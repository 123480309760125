@use "../02-tools/tools.functions" as *;
@use "../01-settings/settings.breakpoints" as *;

header .SearchBox {
  height: auto;
  width: 100%;
  z-index: 1;
  button {
    color: #5d5d5d;
  }
  @include md {
    width: auto;
    height: rem-calc(48px);
  }
  @include lg {
    margin: rem-calc(14px 0 0);
  }
}

cx-searchbox {
  min-width: rem-calc(300px);
  max-width: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  @include md {
    max-width: rem-calc(340px);
    margin-top: 5px;
  }
  label.searchbox {
    background-color: var(--lib-color-neutral-50);
    border-radius: 4px;
    width: 90%;
    margin: rem-calc(15px auto 15px);
    min-width: 340px;
    max-width: 550px;
    input {
      letter-spacing: 0;
      color: var(--lib-color-neutral-900);
      background-color: var(--lib-color-neutral-50);
      top: 0;
      height: rem-calc(55px);
      margin-left: 5%;
      width: 90%;
      border-radius: 4px;
      padding: 20px;
      &::placeholder {
        color: var(--lib-color-neutral-600);
      }
    }
    @include md {
      width: auto;
      margin: rem-calc(-5px auto 15px);
      input {
        margin-left: 0;
        height: auto;
        width: auto;
        padding: 0;
      }
    }
    @include lg {
      margin: rem-calc(0 auto 15px);
    }
  }
  .resetListStyle {
    padding: 0;
  }
  .drop-down-header {
    border-bottom: 1px solid var(--lib-color-neutral-black);
    // padding: rem-calc(15px 15px 10px 15px);
    margin: rem-calc(5px 16px 20px);
    color: var(--lib-color-neutral-900);
    font-size: rem-calc(24px);
    line-height: 1.25;
    letter-spacing: normal;
    list-style: none;
    padding: rem-calc(16px 0);
    display: flex;
    justify-content: space-between;
    @include md {
      margin: rem-calc(5px 16px 20px);
    }
    a {
      &:not([href]):not([tabindex]) {
        font-size: rem-calc(14px);
        color: var(--lib-color-brand-base);
        font-weight: normal;
        border-top: none;
        cursor: pointer;
      }

      &:hover {
        color: var(--lib-color-brand-dark);
      }
    }
  }
  .searchHistory {
    margin-top: -16px;
    padding-bottom: 15px;
    background-color: var(--lib-color-neutral-white);
  }
  .itemHistory {
    color: var(--lib-color-brand-base);
    list-style: none;
    // padding: 8px 8px 8px 0px;
    a {
      line-height: 1.5;
      font-size: rem-calc(16px);
      color: var(--lib-color-brand-base);
      // padding: 8px 0;
    }
  }

  .results {
    top: 0;
    position: relative;
    @include lg {
      top: 0;
    }
    .products {
      display: block;
      background-color: var(--lib-color-neutral-white);
      a {
        border: none;
        color: var(--lib-color-brand-base);
        font-weight: normal;
        font-size: rem-calc(16px);
        text-decoration: underline;
        &.all-results-link:hover {
          background-color: transparent;
          color: var(--lib-color-brand-dark);
        }
      }
    }
  }
  .c-lists {
    position: relative;
    margin-top: -5px;
    padding-bottom: 5px;
    .c-list {
      padding: 0;
      li {
        list-style: none;
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
        &.searchbox-heading {
          padding-left: 0;
          padding-right: 0;
        }
        i {
          color: var(--lib-color-neutral-400);
          margin-top: 5px;
        }
        a {
          margin: 0 0 10px;
          padding: 0 0 0 10px;
        }
      }
    }
    p {
      color: var(--lib-color-neutral-black);
      font-size: rem-calc(12px);
      line-height: 1.5;
      .c-link {
        font-size: rem-calc(12px);
      }
    }
  }
}
