@use "../01-settings/settings.variables" as *;
@use "../01-settings/settings.breakpoints" as *;
@use "../02-tools/tools.functions" as *;

.c-alert-banner,
.alert {
  padding: rem-calc(18px 30px);
  background-color: var(--lib-color-neutral-50);
  font-size: rem-calc(18px);
  color: (--lib-color-neutral-black);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  min-height: rem-calc(72px);
  line-height: 1.5;
  max-width: 1280px;
  margin: 0 auto rem-calc(30px);
  font-weight: normal;
  text-align: left;
  justify-content: flex-start;
  display: flex;
  align-items: center;

  &.alert-danger {
    cx-icon {
      color: red;
    }
  }
  .alert-icon {
    color: #ff9900;
    margin-right: rem-calc(20px);
  }

  .close {
    position: absolute;
    right: calc(30px);
    color: var(--lib-color-brand-base);

    cx-icon {
      color: var(--lib-color-brand-base);
    }
  }
  @include lg {
    padding: rem-calc(18px 60px 18px 60px);
  }
}
