.Section2B cx-banner {
  min-height: 440px;
}

cx-breadcrumb h1 {
  text-transform: none;
}

.btn {
  border-radius: 0.25rem;
  padding: 10px 15px;
  min-height: 44px;
}

.login-details {
  .forgot-text a,
  .new-customer a {
    display: inline;
  }
}

.alert.alert-success {
  display: none;
}

.d-block.modal.fade.show {
  background-color: transparent;
}
