.alert-Influenza-Banner{
    min-height: 79px;
    background-color: #fff;
    margin-bottom: 4px;
    border-left: 4px solid #0056a6;
}

.reservation-history-dropdown .ng-option,.reservation-history-dropdown .ng-option-marked ,
.reservation-history-dropdown .ng-value-label{
    color: #337ab7 !important;
    font-size: 0.9em !important;
}
.displayNone{
    display: none !important;
 }
.warning-text{
    font-family: "Poppins-medium", sans-serif !important;
    font-size: 1.67em !important;
    font-weight: 500 !important;
    color: #2a343f !important;
    margin-left: 0px !important;
    background-image: url("../../assets/icons/warning.svg") !important;
    background-repeat: no-repeat !important;
}
.influenza-Msg-Banner{
    font-family: "Poppins-medium", sans-serif;
    font-size: 1.67em;
    font-weight: 500;
    color: #2a343f;
    margin:0 0 0 5px; 
    position: relative;
    bottom: 6px;
}
.infoBanner-Influenza {

    margin: 2% 0 0 1%;
    padding: 0;
  
}
.noReserve-Middle-Section-Content{
    min-height: 280px;
    background: white;
    padding:4% 0 0 14%;
}
.noReserve-Middle-Chart-Content{
    min-height: 280px;
    background: white;
}
    
.noReserve-Middle-Section-Header{
    background: #549cc9;
    padding: 27px 32px;
    color: #fff;
}
.reserve-Msg{
    color: #5b646e;
    margin-top: 0;
    font-size: 20px;
    font-family: "Poppins-Medium", sans-serif;
    line-height: 24px;
}
.influenza-Vaccine-Span{
    font-size: 1.9em;
}
.influenza-Vaccine-Active-Season-Span{
    font-size: 1.2em;
}
.btn-Reserve-Section{
    margin: 10% 0 0 0;
}
.btn-Reserve{
    background: #0057a6!important;
    color: #ffffff!important;
    border-color: #0057a6!important;

}

.btn-reserve-align{
    margin: 4px 26px 40px;
}
.new-res-padding .new-res {
    padding-bottom: 0;
  }
  .new-res-heading {
    color: #5b646e;
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 20px;
    font-family: "Poppins-Medium", sans-serif;
    line-height: 24px;
  }
  .new-res ul{
  list-style: none;
      padding: 0;
      margin: 0;
  }
  .new-res span {
    color: #2a343f;
}
@media screen and (min-width:300px) and (max-width:700px) {
    .noReserve-Middle-Section-Content{
        min-height: 280px;
        background: white;
        padding:4% 0 0 3%;
    }
    .btn-Reserve-Section{
        margin: 50% 0 0 0;
    }
    .noReserve-Middle-Section-Header {
        background: #549cc9;
        padding: 17px 12px;
        color: #fff;
    }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .btn-Reserve-Section {
        margin: 20% 0 0 0;
    }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .btn-Reserve-Section {
        margin: 20% 0 0 0;
    }
}

//to cover iphone and android
@media screen and (min-device-width: 300px) and (max-device-width: 700px) and
 (orientation: portrait) {
    app-influenza-dashboard{
        min-width: 100%;
    }
    app-flu-confirmation{
        min-width: 100%;
    }
    app-flu-confirmation{
        min-width: 100%;
    }
}
@media (min-width: 576px){
    .checkout-Container-rwd,.est-checkout-Container{
        max-width: 100% !important;
    }
    .mobiledb-Container,.active-season-container,.footer-Container-Section,.infcontact-Container{
        max-width:100%;
        }
}