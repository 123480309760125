.custom-payment-radio-control {
  position: relative;
  width: 10%;
  margin: 0 0 0 14%;
  left: 30px;
  bottom: 25px !important;
}
app-location-strip {
  display: inline !important;
  float: right;
  width: 29%;
  margin-left: 1%;
}

.AccountPageTemplate {
  cx-page-slot.BodyContent.has-components {
    min-width: 100%;
    //background-color: aliceblue;
    padding: 0px !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 1px solid #e8e8e8;
  padding: 13px 10px;
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: var(--lib-color-brand-base);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--lib-color-brand-base);
  background-color: var(--lib-color-brand-base);
}

input[type="radio"]:checked::after {
  color: #fff;
  border-color: var(--lib-color-brand-base);
  background-color: var(--lib-color-brand-base);
}

// input[type=radio]:checked::before {
//     border-color: white !important;
//     background-color: blue!important;
// }
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .AccountPageTemplate {
    cx-page-slot.BodyContent.has-components {
      padding: 0;
    }
  }
  app-location-strip {
    display: inline;
    float: right;
    width: 35%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 3%;
  }
  cx-breadcrumb {
    width: 60% !important;
    display: inline;
    float: left;
    background: aliceblue;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .AccountPageTemplate {
    cx-page-slot.BodyContent.has-components {
      padding: 0;
    }
  }
  app-location-strip {
    display: inline;
    float: right;
    width: 25%;
    margin-right: 5%;
    margin-bottom: 1%;
  }
  cx-breadcrumb {
    width: 64% !important;
    display: inline;
    float: left;
    background: aliceblue;
  }
}

@media screen and (min-width: 300px) and (max-width: 760px) {
  .AccountPageTemplate {
    cx-page-slot.BodyContent.has-components {
      padding: 0;
    }
  }
  app-location-strip {
    display: inline;
    float: left;
    width: 97%;
  }
  cx-breadcrumb {
    // width: 51% !important;
    display: inline;
    float: left;
    background: aliceblue;
  }
}

.user-Profile ng-select .ng-select-container {
  font-size: 1rem !important;
}
.user-Profile ng-select .ng-dropdown-panel-items {
  font-size: 1rem !important;
}

.user-Profile .ng-select span {
  font-family: "NotoSans", sans-serif;
  position: relative;
  color: #5b646e;
  font-size: 12px;
}
