@import "@spartacus/user";
cx-storefront {
  main {
    @media only screen and (max-width: 767px) and (min-width: 320px) {
      width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 100%;
    }
    @media (min-width: 1280px) {
      min-width: 1280px;
      width: 1280px;
      max-width: 1280px;
    }
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    flex: 2 1 100%;
    display: flex;
    flex-flow: column;
    .SearchResultsGridPageTemplate {
      display: flex;
    }
  }
  .BottomHeaderSlot {
    @media only screen and (max-width: 767px) and (min-width: 320px) {
      width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 100%;
    }
    @media (min-width: 1280px) {
      min-width: 1280px;
      width: 1280px;
      max-width: 1280px;
    }
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
