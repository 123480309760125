.header cx-site-context-selector select {
  background: transparent;
}

.header cx-site-context-selector select option {
  color: #0057a6;
}

header .SiteContext:after {
  border: none !important;
}

header div.childs > nav > cx-generic-link > a {
  font-family: "NotoSans", sans-serif;
  font-size: 14px;
  color: #0057a6;
}

header div.childs > nav > cx-generic-link > a:hover {
  color: #0057a6;
}

cx-category-navigation {
  color: #0057a6;
}



.HeaderLinks cx-navigation-ui.flyout.is-open li.is-open > cx-generic-link {
  display: block;
  + button {
      display: block;
      top: 0;
      bottom: auto;
      right: 0;
      left: auto;
      border: none;
  }
}


@media (min-width: 992px) {
  .NavigationBar cx-navigation-ui.flyout h5 {
    margin-left: 40px;
  }
  header .SiteContext cx-paragraph p {
    color: #bfc4c8;
    font-size: 11px;
  }

  header .SiteLogin cx-login {
    display: none;
  }
  header .SiteLogin {
    padding: 0.5rem 1rem;
    display: none;
    width: 20px;
  }
  header {
    background-color: #ffffff;
  }
  header .header {
    max-width: none;
    margin: 0 auto !important;
    padding: 0 0;
  }
}

header {
  box-shadow: 0 0px 5px rgb(23 114 183 / 20%);
  z-index: 9;
}

header .SiteLogo {
  width: 330px;
  margin: 0px 0px 0px 30px;
}

.NavigationBar cx-navigation-ui.flyout h5 {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: currentColor;
  font-family: "Poppins-Medium", sans-serif;
}

.header cx-media img {
  background: transparent;
}

cx-category-navigation h5,
cx-category-navigation cx-navigation-ui > nav > cx-generic-link {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 14px;
}

@media (min-width: 992px) {
  header .SiteLogo {
    width: 784px;
    margin: 0px 0px 0px 30px;
  }
  header .SiteLogo > cx-banner cx-generic-link a .is-initialized {
    width: 450px;
  }
  cx-navigation-ui.flyout > nav > h5 {
    margin-top: 3px;
    padding-top: 20px;
    padding-inline-end: 15px;
    padding-inline-start: 0;
  }
}

header .NavigationBar {
  flex: 100%;
  margin: 0;

  cx-navigation-ui cx-generic-link {
    a {
      text-decoration: underline;
    }
  }
}

cx-icon fas fa-shopping-cart {
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
}

header cx-site-context-selector {
  padding: 0px 0px 0px 40px;
  margin-top: 0px;
}

@media (min-width: 992px) {
  header .SiteContext {
    justify-content: flex-start;
    height: 40px;
    padding: 10px 0px 0px 64px;
    font-size: 11px;
    margin: 0px 0px 0px -10px;
    background-color: #2a343f !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  header .PreHeader {
    background-color: #fff;
    height: 76px;
    padding: 20px 0px 0px 0px;
  }
  header .MiniCart {
    height: 76px;
  }
  header .SiteContext cx-site-context-selector > label {
    position: relative;
    top: 10px;
    left: 20px;
  }
  header .SiteContext :nth-child(3) {
    display: block !important;
    height: 25px;
    width: 12%;
  }

  .LoginPageTemplate .LeftContentSlot .user-form {
    left: 30px;
  }
  cx-page-layout cx-page-slot.MiniCart {
    padding: 25px 24px 0px 18px !important;
  }
  header cx-navigation-ui.flyout h5:hover,
  cx-navigation-ui.flyout cx-generic-link:hover {
    color: #ffffff;
  }

  header .SiteLogo,
  header .MiniCart,
  header .SiteLogin {
    background-color: #ffffff !important;
  }
  header .SiteContext {
    width: 300px;
    height: 40px;
  }

  header .SiteContext :nth-child(2) {
    display: block !important;
    padding: 10px 0px 0px 10px !important;
    width: 12.6%;
    border-top: 0px !important;
    margin: 0px !important;
  }
  header .SiteContext :nth-child(2) {
    padding: 0px !important;
    border-top: 0 !important;
    width: 30% !important;
  }
  header cx-page-layout cx-page-slot {
    display: flex;
    flex-wrap: initial;
  }
  header cx-navigation-ui.flyout > nav > h5 {
    width: 78px;
    .fa-angle-down {
      margin: 1px 0px 5px 0px;
    }
  }
  cx-navigation-ui.flyout > nav > cx-generic-link {
    width: 102px;
  }
  header cx-navigation-ui.flyout > nav > h5,
  cx-navigation-ui.flyout > nav > cx-generic-link {
    text-transform: capitalize;
    font-size: 11px;
    color: #ffffff;
    height: 41px;
    padding: 13px 0px 11px 0px !important;
    // background-color: #2A343F !important;
  }
  header cx-navigation-ui cx-icon:before {
    font-size: 11px;
  }
  header .MiniCart {
    padding: 20px 0px 0px 0px;
    width: 14.4% !important;
    margin: 0px 20px 0px 0px;
  }

  .header cx-media img {
    background: transparent;
    // margin: -10px 0px 0px 0px !important;
    // width: 277px;
    // height: 40px;
  }

  header .SiteContext cx-paragraph {
    width: 39.7%;
    padding: 10px 10px 10px 16px !important;
  }
  @media (max-width: 991.98px) {
    header cx-navigation-ui.flyout {
      color: #0057a6;
      // height: 800px !important;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .LoginPageTemplate .LeftContentSlot .user-form {
    left: 20px !important;
  }

  cx-page-layout.LoginPageTemplate {
    min-height: 482px;
    width: 100% !important;
  }

  cx-navigation-ui.flyout > nav > .wrapper {
    margin: 3px -8px !important;
  }
  header .SiteLogo {
    width: 531px;
    margin: 0px 0px 0px 50px;
  }
  header .SiteLogo > cx-banner cx-generic-link a .is-initialized {
    width: 450px;
  }
  header .SiteContext {
    padding: 11px 0px 0px 50px;
    background-color: #2a343f !important;
    flex: none !important;
  }
  header .SiteLogo {
    width: 529px;
    margin: 0px 0px 0px 36px !important;
  }
  .MiniCart app-mini-cart > .cart-icons {
    padding-right: 35px;
  }
  .NavigationBar cx-navigation-ui.flyout h5 {
    margin-left: 40px;
  }

  .BottomHeaderSlot {
    padding: 0px 30px 0px 0px !important;
  }

  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
  }

  header .MiniCart {
    padding: 20px 0px 0px 0px !important;
  }
  header .MiniCart {
    padding: 20px 0px 0px 0px !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  header cx-navigation-ui.flyout h5:hover {
    color: #fff;
  }
  header .SiteContext cx-site-context-selector {
    display: block;
  }

  header .SiteContext :nth-child(2) {
    display: block;
    margin: -1px -4px 0px 0px;
  }
  .header .SiteContext :nth-child(3) {
    display: none;
  }
  cx-navigation-ui.flyout > nav > h5 {
    width: 116%;
    font-size: inherit;
    height: 40px;
    margin: 0px 0px 0px 0px;
  }
  app-quick-order cx-searchbox label {
    max-width: 278px !important;
    min-width: 312px !important;
    width: 312px !important;
  }

  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  cx-page-layout.LoginPageTemplate {
    min-height: 548px !important;
    width: 100% !important;
  }
  cx-page-layout.LoginPageTemplate .customer-login {
    width: 96% !important;
    margin: 0px 0px 0px -17px;
    display: block !important;
  }

  .header cx-media img {
    background: transparent;
    margin: 0px 0px 0px 0px;
  }
  header .MiniCart {
    padding: 10px 0px 10px 0px;
    margin: 0px !important;
  }
}
header.is-expanded {
  .navigation {
    top: 0;
    padding-top: 5%;
    position: fixed;
    z-index: 1000;
  }
  .is-active.cx-hamburger {
    position: fixed;
    top: 5px;
    right: 10px;
  }
  .PreHeader {
    height: 50px;
    z-index: 2000;
  }
}
@media (max-width: 991.98px) {
  header .SiteContext,
  header .SiteContext cx-site-context-selector {
    display: none;
  }
  header .SiteContext cx-paragraph {
    display: inline-block;
    padding: 10px;
    background-color: #2a343f !important;
    height: 40px;
    color: #bfc4c8;
  }
  header {
    background-color: white;
  }

  cx-media img {
    max-width: fit-content;
  }

  header .SiteLogo {
    margin: 0px !important;
  }
}

@media (max-width: 991.98px) {
  header .SiteContext cx-site-context-selector {
    display: block;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .header cx-media img {
    width: 277px;
  }
  cx-navigation-ui.flyout .childs[columns="1"] {
    column-count: 1;
    padding: 10px;
  }

  header .header {
    background-color: #2a343f !important;
  }

  header .SiteContext cx-site-context-selector {
    display: block;
    padding: 10px;
    background-color: #2a343f !important;
    width: 16%;
    margin: 0px 0px 7.2px 0px;
  }
  header .SiteContext .EmptyCartMiddleContent app-quick-order label {
    width: 27vw;
    min-width: 750px !important;
    max-width: 750px !important;
  }
  .customer-login {
    margin: 0px 0px 0px 0px !important;
  }
  cx-page-layout.LoginPageTemplate {
    min-height: 499px !important;
    width: 101%;
  }
  header .SiteLogo {
    width: 364px !important;
  }
  .MiniCart app-mini-cart > .cart-icons {
    padding-right: 21px !important;
  }

  header .header .SiteContext cx-paragraph > p {
    color: #bfc4c8;
    font-size: 11px;
  }

  .NavigationBar cx-navigation-ui.flyout h5 {
    margin-left: 0px !important;
  }
  .NavigationBar cx-navigation-ui {
    height: 953px !important;
  }
  .PreHeader cx-hamburger-menu {
    margin: 0px 0px -4px 0px;
  }
  header .header .SiteLogin {
    display: none;
  }

  header.is-expanded {
    .PreHeader {
      display: flex;
      flex: 1 var(--cx-flex-basis, 100%);
      background-color: #2a343f !important;
    }
  }
  header .cx-hamburger {
    float: right;
  }
  header .SiteContext cx-paragraph {
    width: 31%;
    float: right;
    padding: 10px 0px 10px 16px;
  }

  .header .MiniCart {
    padding: 20px 0px 20px 0px !important;
    background-color: #fff;
    height: 75px;
  }

  Section2B cx-banner cx-generic-link a cx-media img {
    display: none;
  }
  .header .PreHeader {
    background-color: white;
    height: 75px;
    padding: 18px 0px 0px 0px;
    width: 62px;
  }
}

@media screen and (min-width: 320px) and (max-width: 760px) and (orientation: portrait) {
  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  cx-navigation-ui.flyout > nav > h5 {
    width: 162px;
    height: 40px;
  }

  header .SiteContext cx-paragraph {
    width: 100%;
    padding: 10px 10px 10px 16px;
    border-bottom: 1px solid #fff;
  }

  header .MiniCart {
    padding: 10px 0px 10px 0px;
    margin: 0px !important;
  }
}

@media screen and (min-width: 300px) and (max-width: 650px) and (orientation: landscape) {
}

//hamburger
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  app-quick-order cx-searchbox label {
    max-width: 278px !important;
    min-width: 225px !important;
    width: 235px !important;
  }

  cx-page-slot.SiteLogin {
    display: none !important;
  }

  cx-page-layout.LoginPageTemplate .customer-login {
    width: 43% !important;
    position: fixed;
  }
  header cx-navigation-ui.flyout.is-open nav.is-open > .wrapper {
    height: auto;
    background: #ffffff;
    width: 199px !important;
    margin: 0px -26px !important;
  }
  header.is-expanded .PreHeader {
    z-index: 2000;
    flex: 1 1 var(--cx-flex-basis, 100%);
    margin: 0px 0px -4px 0px !important;
  }
  header .cx-hamburger {
    float: right;
    margin: 0px 0px 0px 0px !important;
  }
  .header cx-hamburger-menu .hamburger-inner::after {
    cx-page-slot.SiteContext > cx-paragraph {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  .SearchResultsGridPageTemplate .product-page .main .content-right {
    flex: initial;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 346px !important;
  }

  .header cx-media img {
    background: transparent;
    margin: 0px 0px 0px 0px;
  }

  header .MiniCart {
    padding: 10px 0px 10px 0px;
    margin: 0px !important;
  }
  header.is-expanded .PreHeader {
    z-index: 2000;
    flex: 1 1 var(--cx-flex-basis, 100%);
    margin: 0px 5px 0px 0px;
    position: absolute;
    top: 0;
    right: 0;
  }
  cx-navigation-ui.flyout h5 .wrapper .childs cx-generic-link {
    border-bottom: none;
  }

  header .cx-hamburger {
    float: right;
  }
  header .SiteContext:nth-child(2) {
    width: 100%;
    height: 40px;
    background-color: #2a343f !important;
    padding: 10px 10px 10px 10px;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: landscape) {
  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
  }
  header .SiteContext cx-site-context-selector {
    width: 7% !important;
  }
  .header cx-media img {
    width: 277px;
  }
  header .SiteLogo {
    width: 303px !important;
  }

  .MiniCart app-mini-cart > .cart-icons {
    padding-right: 21px !important;
  }

  header .header .SiteContext cx-paragraph > p {
    color: #bfc4c8;
    font-size: 11px;
  }

  .NavigationBar cx-navigation-ui.flyout h5 {
    margin-left: 0px !important;
  }
  .NavigationBar cx-navigation-ui {
    height: 953px !important;
  }
  .PreHeader cx-hamburger-menu {
    margin: 0px 0px -4px 0px;
  }
  header .header .SiteLogin {
    display: none;
  }
  header .header .SiteLogo {
    padding: 20px 0px 0px 10px;
  }

  header.is-expanded .PreHeader {
    display: flex;
    flex: 1 var(--cx-flex-basis, 100%);
  }
  header .cx-hamburger {
    float: right;
  }
  header .SiteContext cx-paragraph {
    width: 40% !important;
    float: right;
    padding: 10px 0px 10px 16px;
  }

  .header .MiniCart {
    padding: 20px 0px 20px 0px !important;
    width: 33.4%;
  }
  .Section2B cx-banner cx-generic-link a cx-media img {
    display: none;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: landscape) {
  cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
    max-width: 90% !important;
    margin-left: 0px;
    margin-right: 0px;
    background: #ffffff;
  }
  cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
    margin: 0px;
  }
}

//ipad changes

@media screen and (min-width: 360px) and (max-width: 640px) and (orientation: portrait) {
  header cx-navigation-ui.flyout h5:hover {
    color: #fff;
  }
  header .SiteContext :nth-child(2) {
    display: block;
    margin: 0px -9px 0px 0px;
  }
  header .SiteContext cx-site-context-selector {
    width: 100%;
    background-color: #2a343f;
  }
  .header cx-site-context-selector select {
    padding: 10px 10px 10px 1px;
  }
  header .SiteContext :nth-child(3) {
    display: none;
  }

  cx-page-layout.LandingPage2Template
    cx-page-slot.Section2B
    .user-form
    .customer-login,
  cx-page-layout.HomePageTemplate
    cx-page-slot.Section2B
    .user-form
    .customer-login {
    display: none;
  }
}

@media screen and (min-width: 360px) and (max-width: 640px) and (orientation: landscape) {
  cx-page-layout.LandingPage2Template cx-page-slot cx-banner cx-media img,
  cx-page-layout.HomePageTemplate cx-page-slot cx-banner cx-media img {
    display: block;
    height: 457px !important;
  }
  cx-page-slot.SiteContext.has-components {
    display: contents;
  }
  header .SiteContext :nth-child(2) {
    display: inherit !important;
    padding: 0px;
    width: 33% !important;
    margin: 0px !important;
    height: 34.5px;
  }
  .LandingPage2Template cx-paragraph .home-banner-left h2,
  .HomePageTemplate cx-paragraph .home-banner-left h2 {
    position: absolute;
    margin: 26px 0px 0px 0px;
    width: 100%;
    font-size: 30px !important;
    margin: 26px 0px 0px 0px !important;
  }
  cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
    max-width: 90% !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  cx-navigation-ui.flyout > nav > .wrapper {
    background: white;
    z-index: 999;
    position: absolute;
  }

  .header cx-site-context-selector select {
    width: 30%;
  }
  header .SiteContext :nth-child(2) {
    display: block !important;
    padding: 0px;
    width: 30%;
    margin: 0px !important;
    height: 33.5px;
  }
  // header .SiteContext :nth-child(3){
  //   display:none;
  // }
}

app-quick-order cx-searchbox label {
  max-width: 375px;
  min-width: 375px;
  width: 375px;
}

@media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  header .NavigationBar {
    padding: 0px 0px 20px 0px;
  }
  .header .SiteLogo {
    width: 43% !important;
    margin: 0px 0px 0px -14px !important;
    cx-banner cx-generic-link {
      margin: 0px 0px 0px 57px;
    }
  }
  header cx-navigation-ui.flyout > nav > h5 {
    font-size: 14px;
  }
  .header .MiniCart {
    margin: 0px -1px 0px 0px !important;
    width: 21.4% !important;
  }

  header .SiteContext {
    flex: 50%;
    display: contents !important;
  }

  header .SiteContext :nth-child(2) {
    width: 22% !important;
  }
  header .SiteContext cx-paragraph {
    width: 28.7%;
  }
  header .SiteContext :nth-child(3) {
    display: none !important;
  }

  header .PreHeader {
    height: 40px;
  }
  header .header {
    background-color: #fff !important;
  }
  header cx-navigation-ui.flyout > nav > h5 {
    width: auto;
  }
  .header .NavigationBar .flyout nav:nth-of-type(2) cx-generic-link a {
    padding: 4px 0px 0px 0px;
  }
  .header .NavigationBar .flyout nav:nth-of-type(3) h5 {
    margin-left: 115px;
  }
}

@media screen and (min-width: 414px) and (max-width: 736px) and (orientation: landscape) {
  header .SiteLogo {
    width: 288px !important;
  }
  header .SiteContext cx-paragraph {
    width: 36%;
  }
}

@media screen and (min-width: 360px) and (max-width: 800px) and (orientation: landscape) {
  header .SiteLogo {
    width: 330px !important;
  }
}

header .SiteLogo {
  position: relative;
  transform: translate(28%);
}
.ProductDetailsPageTemplate .BottomHeaderSlot cx-breadcrumb h1 {
  display: none;
}



@import '../../../../library/01-settings/settings.breakpoints';
@import '../../../../library/01-settings/settings.variables';
@import '../../../../library/02-tools/tools.functions';

// New Nav

.header .HeaderLinks {
  cx-navigation-ui.flyout {
    > nav > ul > li {
      position: relative;
      margin-right: 30px;
      margin-top: 3px;
      & > button {
        position: absolute;
        width: 100%;
        display: block;
        text-align: right;
        padding-top: 0;
        padding-bottom: 37px;
      }
    }
    cx-generic-link {
      margin-right: 5px;
      color: var(--lib-color-neutral-black);
      text-transform: capitalize;
    }
  }

  cx-navigation-ui {
    background-color: transparent;
    padding: 10px 0px 0 50px;
    position: relative;
    padding: 0;
    cx-generic-link {
      a {
        text-transform: capitalize;
        font-size: $size-n;
        cursor: pointer;
        font-family: var(--lib-font-family-sans);
        font-weight: 700;
        letter-spacing: 0px;
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
     
      }
    }
    button .fa-angle-down::before {
      color: var(--lib-color-neutral-black);
    }
    & > nav > ul > li > .wrapper {
      border: none;
      box-shadow: 0 2px 3px 0 var(--lib-color-brand-lighter);
      padding: 3px 16px;
      margin: -12px -20px;
      margin-top: 55px;

      & a {
        padding: 10px 0;
        font-weight: normal;
        font-size: $size-s;
        color: var(--lib-color-brand-base);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
@include lg {
  header {
    .header {
      justify-content: flex-start!important;
    }
    .HeaderLinks {
      width: 28%!important;
      padding-top: 23px;
    }
    .SiteLogo {
      width: 32.5%!important;
      margin: 0 0 0 54px!important;
    }
    .MiniCart {
      width: 7%!important;
    }
    .SearchBox {
      width: 25%!important;
    }
    .NavigationBar {
      border-top: 1px solid var(--lib-color-neutral-200);
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}