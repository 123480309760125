@media only screen and (max-width: 768px) {
  #continueShoppingId {
    margin: 0;
  }

  section.preheader > section {
    section.float-left {
      float: none !important;
    }

    a {
      min-height: 0;
    }
  }

  .terms-Conditions,
  .switch-whs-popup {
    a {
      min-height: 0;
      display: inline;
    }
  }

  cx-page-layout {
    .details-header {
      .middle-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
          min-height: 22px;
          text-decoration: none;
        }
      }
    }
  }
}

div.modal-dialog {
  max-width: none;
  width: fit-content;
}

.product-page .header .content.content-right section.bottom {
  min-height: auto;
}

.standart-cart .checkout .add-products {
  .ad-product-details.cartProductSaveDetails {
    padding-left: 0;
    padding-right: 0;
  }

  .quick-prod-details {
    padding-left: 0;

    .row.unit-calc {
      margin: 0;
      justify-content: space-between;

      &:before,
      &:after {
        content: "";
        display: none;
      }

      .price-details,
      .units,
      .quickorder-button {
        width: auto;
      }

      .quickorder-button {
        @media only screen and (max-width: 768px) {
          width: 100%;
        }

        & + section {
          display: none;
        }
      }
    }

    .m0.left-align {
      margin-left: 0;
    }
  }
}

.popover .popover-body {
  .btn {
    min-height: 30px;
  }
}

.delivary_opt {
  .custom-radio {
    input.custom-control-input {
      cursor: default;
    }
  }
}

cx-page-layout.AccountPageTemplate cx-page-slot {
  padding-top: 0;
}

section.facets-filter {
  #cx-active-facets-groupName {
    flex: 0;
    font-size: 12px;
    min-width: fit-content;
    margin: 7px 10px 0 0;
    color: #5b646e;
  }

  cx-active-facets a > span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .infoBanner {
    .warning-text {
      a {
        line-break: anywhere;
      }
    }
  }

  .ad-files-text {
    display: flex;
    padding-right: 20px;
    justify-content: end;

    a.download {
      min-height: 0;
    }
  }
  .cart-price-data {
    .actionOptions-small {
      a {
        min-height: 0;
        display: inline;
      }
    }
  }

  .edit-saved-cart {
    .actionOptions {
      a {
        min-height: 0;
        display: inline;
      }
    }
  }

  .xls-pdf-invoice {
    div a {
      min-height: 0;
      display: inline;
    }
  }
}
